import { IAppraisal } from './appraisal';
import { IParticipant } from './participant';
import { IUser } from './user';

export enum GoalProcessTypes {
    appraisal = 'appraisal',
    regulation = 'regulation',
    appraisal_and_regulation = 'appraisal_and_regulation'
}

export interface IGoal {
    uid: string,
    description: string,
    congruent?: boolean,
    process?: GoalProcessTypes,

    createdAt?: string,
    updatedAt?: string,

    createdBy?: IUser,
    updatedBy?: IUser,

    appraisal?: IAppraisal,

    owner?: IParticipant,
    nonOwner?: IParticipant,
}
