import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowLeft,
  faLock,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { secondsToTimeString, timeToSeconds } from '../../../../helpers/datetime-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { narrativeDisable } from '../../../../api/actions/narrative/narrative-disable';
import { narrativeEnable } from '../../../../api/actions/narrative/narrative-enable';
import { narrativeGenerateSoundtrack } from '../../../../api/actions/narrative/narrative-generate-soundtrack';
import { getNarrative } from '../../../../api/actions/narrative/narrative-get';
import { narrativeSave } from '../../../../api/actions/narrative/narrative-save';
import { Block } from '../../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../../components/shared/breadcrumbs/breadcrumbs';
import { Button } from '../../../../components/shared/button/button';
import { CheckBoxWrapper } from '../../../../components/shared/checkbox/checkbox-wrapper';
import { LinkButton } from '../../../../components/shared/link-button/link-button';
import { Loader } from '../../../../components/shared/loader/loader';
import { SelectField } from '../../../../components/shared/select/select';
import { Tabs } from '../../../../components/shared/tabs/tabs';
import { UploaderField } from '../../../../components/shared/uploader/uploader';
import { UploaderImagePreview } from '../../../../components/shared/uploader/uploader-image-preview';
import { Video } from '../../../../components/shared/video/video';
import { getNarrativeFilePath } from '../../../../config/app-config';
import { enumToValueArr } from '../../../../helpers/enum-helpers';
import { basePerformError } from '../../../../helpers/error-helpers';
import { joinPath } from '../../../../helpers/path-helpers';
import { EntityTypes } from '../../../../models/enums/entity-types';
import { PlatformTypes } from '../../../../models/enums/platform-types';
import { IFile } from '../../../../models/file';
import {
  ContentAgeRating,
  INarrative,
  NarrativeTypes,
} from '../../../../models/narrative';
import { IDictionary } from '../../../../types/dictionary';
import { EntityTasksList } from '../../../tasks/parts/entity-tasks-list';
import { CharactersManagement } from './components/characters-management/characters-management';
import { EpisodesManagement } from './components/episodes-management/episodes-management';
import { ExternalReferencesManagement } from './components/external-references-management/external-references-management';
import { ScenesList } from './components/scenes-list/scenes-list';
import { TopicsManagement } from './components/topics-management/topics-management';
import { NarrativeStatusTypes } from '../../../../models/enums/narrative-status-types';
import { getAllEndSurveys } from '../../../../api/actions/end-survey/survey-get-all';
import { compareText } from '../../../../helpers/array-helpers';
import { getEmotionEpisodesForNarrative } from '../../../../api/actions/emotion-episode/emotion-episode-get-all-for-narrative';
import { IEmotionEpisode } from '../../../../models/emotion-episode';
import { IEndSurvey } from '../../../../models/end-survey';
import moment from 'moment-timezone';
import styles from './narrative-editor.module.scss';

interface IForm {
  title: string;
  minAgeRating: number;
  description?: string;
  platformType?: string;
  platforms?: string[],
  mediaType: string;
  image?: IFile;
  contentUrl?: string;
  comingSoon?: boolean;
  status?: string;
  duration?: string;
  audioSignature?: IFile;
  catalogFile?: IFile;
  soundtrackFile?: IFile;
  endSurvey?: string;
}

export function NarrativeEditor(props: RouteComponentProps<{ uid: string }>) {
  const [loading, setLoading] = useState(true);
  const [tasksCount, setTasksCount] = useState<any>();
  const [narrative, setNarrative] = useState<INarrative>();
  const [allEmotionEpisodes, setAllEmotionEpisodes] =
    React.useState<IEmotionEpisode[]>();
  const [surveys, setSurveys] = useState<IEndSurvey[]>([]);
  const [addNewPlatform, setAddNewPlatform] = useState<string>();

  const loadData = React.useCallback(
    async (uid?: string) => {
      setLoading(true);
      try {
        if (uid || props.match.params.uid !== '_') {
          setSurveys(
            (await getAllEndSurveys(true)).sort((el1, el2) =>
              compareText(el1, el2, (el) => el.name)
            )
          );

          const narr = await getNarrative(uid || props.match.params.uid);
          setNarrative(narr);

          const availableEmotionEpisodes = narr.scenes
            ?.flatMap<IEmotionEpisode>((scene) => scene.emotionEpisodes || [])
            ?.filter((ee) => ee.available === true)
            .sort((ee1, ee2) => ee1.start - ee2.start);

          setAllEmotionEpisodes(availableEmotionEpisodes);
        } else {
          setNarrative({} as INarrative);
        }
      } catch (err) {
        basePerformError(err, props.history);
      }
      setLoading(false);
    },
    [props.history, props.match.params.uid]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = async (
    values: IForm,
    {
      setSubmitting,
      setErrors,
    }: {
      setSubmitting: (status: boolean) => void;
      setErrors: (errors: IDictionary<string>) => void;
    }
  ) => {
    setLoading(true);
    try {
      const newNarrative: Partial<INarrative> = {
        uid: narrative?.uid,
        title: values.title?.trim(),
        minAgeRating: values.minAgeRating,
        description: values.description,
        platformType: values.platformType as PlatformTypes,
        platforms: values.platforms,
        contentUrl: values.contentUrl,
        mediaType: values.mediaType as NarrativeTypes,
        fileName: values.image?.url,
        audioSignatureFileName: values.audioSignature?.url,
        audioCatalogFileName: values.catalogFile?.url,
        soundtrackFileName: values.soundtrackFile?.url,
        comingSoon: narrative?.available ? false : values.comingSoon,
        status: values.status,
        duration: timeToSeconds(values.duration),
        endSurveyUid: values.endSurvey,
      };

      const uid = await narrativeSave(
        newNarrative,
        values.image && values.image.file,
        values.audioSignature && values.audioSignature.file,
        values.catalogFile && values.catalogFile.file,
        values.soundtrackFile && values.soundtrackFile.file,
      );
      if (!narrative?.uid) {
        props.history.push(
          joinPath(props.match.url.replace(/\/_\/?$/, ''), uid)
        );
      }
      await loadData(uid);
      toast.success('Item has been successfully saved');
    } catch (err) {
      basePerformError(err, props.history);
    }
    setSubmitting(false);
    setLoading(false);
  };

  const enableNarrative = async () => {
    if (!narrative?.uid) return;
    try {
      await narrativeEnable(narrative.uid);
      setNarrative(undefined);
      loadData();
      toast.success('Item has been successfully saved');
    } catch (err) {
      basePerformError(err, props.history);
    }
  };

  const disableNarrative = async () => {
    if (!narrative?.uid) return;
    try {
      await narrativeDisable(narrative.uid);
      setNarrative({ ...narrative, available: false });
      toast.success('Item has been successfully saved');
    } catch (err) {
      basePerformError(err, props.history);
    }
  };

  const getValidationSchema = () => {
    return Yup.object<IForm>({
      title: Yup.string().trim().label('Title').required().min(3).max(50),
      minAgeRating: Yup.number().label('Age Rating').required(),
      description: Yup.string().trim().min(3).max(255),
      mediaType: Yup.string()
        .required('Please select Type')
        .oneOf(enumToValueArr(NarrativeTypes)),
      platformType: Yup.string()
        .required('Please select Platform')
        .oneOf(enumToValueArr(PlatformTypes)),
      contentUrl: Yup.string().trim().url(),
    });
  };

  const generateSoundtrack = async () => {
    if (!narrative?.uid) return;
    try {
      const result = await narrativeGenerateSoundtrack(narrative.uid);
      if (result) {
        setNarrative(undefined);
        loadData();
        toast.success('Soundtrack successfully generated');
      } else {
        toast.error("Unable to generate soundtrack");
      }
    } catch (err) {
      basePerformError(err, props.history);
      toast.error("Error: Unable to generate soundtrack");
    }    
  }

  const generateAndDownloadCsv = () => {
    try {
      const csvRA = ['id,timeRanges,title'];
      //if(narrative) csvRA.push(`${narrative.title}`)

      allEmotionEpisodes?.forEach((i) => {
        if (i.available)
          csvRA.push(`${i.uid},${i.start}..<${i.end},${i.description}`);
      });
      const csvString = csvRA.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', 'download.csv');
      a.click();
    } catch (e) {
      console.error(e);
    }
  };

  const renderForm = ({
    errors,
    touched,
  }: FormikProps<IForm>): React.ReactElement => {
    return (
      <div className={styles.formContainer}>
        <Form noValidate>
          <div className="form-buttons">
            {narrative?.uid && !narrative.available && (
              <LinkButton onClick={enableNarrative}>
                <FontAwesomeIcon icon={faUnlock} /> Enable
              </LinkButton>
            )}
            {narrative?.uid && narrative.available && (
              <LinkButton onClick={disableNarrative}>
                <FontAwesomeIcon icon={faLock} /> Disable
              </LinkButton>
            )}
            {narrative?.uid && (
              <LinkButton
                onClick={() => {
                  /* */
                }}
                className="red"
              >
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </LinkButton>
            )}
          </div>

          {narrative?.enabledAt && (
            <div className="form-item">
              <label>
                <div className="form-label">Enabled At: {moment(narrative?.enabledAt).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}</div> 
              </label>
            </div>
          )}

          <div className="form-item">
            <label>
              <div className="form-label required">Title</div>
              <Field type="text" name="title" />
            </label>
            <div className="errors">{touched.title && errors.title}</div>
          </div>
          <div className="form-item">
            <label>
              <div className="form-label required">Status</div>
              <Field
                component={SelectField}
                name="status"
                emptyTitle=""
                data={Object.keys(NarrativeStatusTypes).map((t: string) => ({
                  uid: t,
                  name: NarrativeStatusTypes[t].toUpperCase(),
                }))}
              />
            </label>
            <div className="errors">{touched.status && errors.status}</div>
          </div>
          <div className="form-item">
            <label>
              <div className="form-label required">Age Rating:</div>
              <Field
                component={SelectField}
                name={'minAgeRating'}
                emptyTitle=""
                data={Object.keys(ContentAgeRating).map((rating) => ({
                  uid: parseInt(rating),
                  name: ContentAgeRating[parseInt(rating)],
                }))}
              />
            </label>
            <div className="errors">{errors.minAgeRating}</div>
          </div>
          <div className="form-item">
            <label>
              <div className="form-label">Description</div>
              <Field component="textarea" name="description" />
            </label>
            <div className="errors">
              {touched.description && errors.description}
            </div>
          </div>
          <div className="form-item">
            <label>
              <div className="form-label required">Type</div>
              <Field
                component={SelectField}
                name="mediaType"
                disabled={
                  Object.keys(NarrativeTypes).includes(
                    narrative?.mediaType || ''
                  ) && !!narrative?.uid
                }
                emptyTitle=""
                data={Object.keys(NarrativeTypes).map((t) => ({
                  uid: t,
                  name: t.toUpperCase(),
                }))}
              />
            </label>
            <div className="errors">
              {touched.mediaType && errors.mediaType}
            </div>
          </div>

          {narrative?.uid && narrative.mediaType !== NarrativeTypes.series && (
            <div className="form-item">
              <label>
                <div className="form-label">
                    Duration
                </div>
                <Field type="text" name="duration" />
              </label>
              <div className="errors">{touched.duration && errors.duration}</div>
              </div>            
          )}

          <div className="form-item">
                <label>
                  <div className="form-label">
                    Platforms
                  </div>
                  <Field
                    component={SelectField}
                    name="platforms"
                    data={Object.keys(PlatformTypes).map(el => ({
                        uid: PlatformTypes[el],
                        name: PlatformTypes[el].toUpperCase(),
                    }))}
                    multiSelect={true}
                    onAddNew={setAddNewPlatform}
                    />
                </label>
              <div className="errors">{touched.platforms && errors.platforms}</div>
          </div>

          <div className="form-item">
            <label>
              <div className="form-label required">Platform</div>
              <Field
                component={SelectField}
                name="platformType"
                emptyTitle=""
                data={Object.keys(PlatformTypes).map((t) => ({
                  uid: t,
                  name: t.toUpperCase(),
                }))}
              />
            </label>
            <div className="errors">
              {touched.platformType && errors.platformType}
            </div>
          </div>
          {console.log(getNarrativeFilePath(narrative?.uid || ''))}
          <div className="form-item">
            <label>
              <div className="form-label">Image</div>
              <Field
                component={UploaderField}
                name="image"
                acceptFileTypes=".png,.jpg,.jpeg"
                path={getNarrativeFilePath(narrative?.uid || '')}
                previewComponent={UploaderImagePreview}
              />
            </label>
            <div className="errors">{touched.image && errors.image}</div>
          </div>

          {narrative?.uid && narrative.mediaType !== NarrativeTypes.series && (
            <>

              <button
                type="button"
                onClick={generateSoundtrack}
                disabled={allEmotionEpisodes?.length === 0}
                className={styles.soundtrackCreateButton}
              >
                Generate Soundtrack
              </button>

              <div className="form-item">
                <label>
                  <div className="form-label">Soundtrack File</div>
                  <Field
                    component={UploaderField}
                    name="soundtrackFile"
                    acceptFileTypes=".mp3"
                    path={getNarrativeFilePath(narrative?.uid || '')}
                  />
                </label>
                <div className="errors">
                  {touched.soundtrackFile && errors.soundtrackFile}
                </div>
              </div>

              <div className="form-item">
                <label>
                  <div className="form-label">Audio Signature File</div>
                  <Field
                    component={UploaderField}
                    name="audioSignature"
                    acceptFileTypes=".shazamsignature"
                    path={getNarrativeFilePath(narrative?.uid || '')}
                  />
                </label>
                <div className="errors">
                  {touched.audioSignature && errors.audioSignature}
                </div>
              </div>
              <div className="form-item">
                <label>
                  <div className="form-label">Audio Catalog File</div>
                  <Field
                    component={UploaderField}
                    name="catalogFile"
                    acceptFileTypes=".shazamcatalog"
                    path={getNarrativeFilePath(narrative?.uid || '')}
                  />
                </label>
                <div className="errors">
                  {touched.catalogFile && errors.catalogFile}
                </div>
              </div>

              <button
                type="button"
                onClick={generateAndDownloadCsv}
                disabled={allEmotionEpisodes?.length === 0}
                className={styles.csvButton}
              >
                Download Shazam Segment CSV
              </button>

            </>
          )}

          <div className="form-item">
            <label>
              <div className="form-label">Content URL</div>
              <Field type="text" name="contentUrl" />
            </label>
            <div className="errors">
              {touched.contentUrl && errors.contentUrl}
            </div>
          </div>

          {narrative?.uid && (
            <div className="form-item">
              <label>
                <div className="form-label">End Survey</div>
                <Field
                  component={SelectField}
                  name={'endSurvey'}
                  emptyTitle=""
                  data={surveys
                    ?.sort((a1, a2) =>
                      a1.name > a2.name ? 1 : a1.name < a2.name ? -1 : 0
                    )
                    .map((a: IEndSurvey) => ({ uid: a.uid, name: a.name }))}
                />
              </label>
              <div className="errors">{errors.endSurvey}</div>
            </div>
          )}

          {!narrative?.available && (
            <div className="form-item">
              <label>
                <div className="form-label">&nbsp;</div>
                <CheckBoxWrapper label="Coming Soon">
                  <Field type="checkbox" name="comingSoon" />
                </CheckBoxWrapper>
              </label>
              <div className="errors">
                {touched.comingSoon && errors.comingSoon}
              </div>
            </div>
          )}

          <div className="form-buttons">
            <Button
              onClick={() => props.history.push('/narratives')}
              className="gray"
            >
              <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
            </Button>
            {loading ? (
              <Loader />
            ) : (
              <Button type="submit">
                <span>{narrative?.uid ? 'Save' : 'Create'}</span>
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  };

  return (
    <>
      {narrative && (
        <>
          <Tabs
            data={[
              {
                title: 'Info',
                content: (
                  <>
                    <br />
                    <Block className={styles.editor}>
                      <AddBreadcrumbsItem
                        title={
                          narrative && narrative.uid
                            ? `Edit: ${narrative.title}`
                            : 'Add Narrative'
                        }
                        url={props.match.url}
                      />
                      <Formik
                        initialValues={{
                          title: narrative.title || '',
                          minAgeRating: narrative.minAgeRating || 0,
                          description:
                            (narrative && narrative.description) || '',
                          mediaType: (narrative && narrative.mediaType) || '',
                          platformType:
                            (narrative && narrative.platformType) || '',
                          platforms: narrative?.platforms || [],
                          duration: (narrative && secondsToTimeString(narrative.duration)) || '',  
                          image:
                            (narrative &&
                              ((narrative.fileName && {
                                url: narrative.fileName,
                              }) ||
                                (narrative.imageFile && {
                                  file: narrative.imageFile,
                                }))) ||
                            undefined,
                          audioSignature:
                            (narrative &&
                              narrative.audioSignatureFileName && {
                                url: narrative.audioSignatureFileName,
                              }) ||
                            undefined,
                          catalogFile:
                            (narrative &&
                              narrative.audioCatalogFileName && {
                                url: narrative.audioCatalogFileName,
                              }) ||
                            undefined,
                          soundtrackFile:
                            (narrative &&
                              narrative.soundtrackFileName && {
                                url: narrative.soundtrackFileName,
                              }) ||
                            undefined,

                          contentUrl: (narrative && narrative.contentUrl) || '',
                          comingSoon: narrative.comingSoon || false,
                          status:
                            narrative?.status ||
                            (narrative?.available
                              ? NarrativeStatusTypes.fully_mapped
                              : NarrativeStatusTypes.new),
                          endSurvey: narrative?.endSurveyUid || '',
                        }}
                        validationSchema={getValidationSchema}
                        onSubmit={handleSubmit}
                      >
                        {renderForm}
                      </Formik>

                      <div className={styles.videoContainer}>
                        {narrative.contentUrl &&
                          narrative.contentUrl.trim() && (
                            <Video url={narrative.contentUrl} controls={true} />
                          )}
                      </div>
                    </Block>

                    {narrative?.uid && (
                      <>
                        <TopicsManagement
                          narrative={narrative}
                          reloadData={loadData}
                        />

                        <ExternalReferencesManagement
                          narrative={narrative}
                          reloadData={loadData}
                        />

                        {narrative.mediaType === NarrativeTypes.series && (
                          <EpisodesManagement
                            narrative={narrative}
                            reloadData={loadData}
                            addEpisode={() => {
                              props.history.push(
                                joinPath('/', props.match.url, 'episodes/_')
                              );
                            }}
                            editEpisode={(uid: string) =>
                              props.history.push(
                                joinPath('/', props.match.url, 'episodes', uid)
                              )
                            }
                            history={props.history}
                          />
                        )}

                        <CharactersManagement
                          narrative={narrative}
                          reloadData={loadData}
                        />

                        <ScenesList
                          narrative={narrative}
                          addScene={() => {
                            props.history.push(
                              joinPath('/', props.match.url, 'scenes/_')
                            );
                          }}
                          editScene={(uid: string) =>
                            props.history.push(
                              joinPath('/', props.match.url, 'scenes', uid)
                            )
                          }
                          reloadData={loadData}
                          history={props.history}
                        />
                      </>
                    )}
                  </>
                ),
              },
              {
                title: <>Tasks {tasksCount}</>,
                content: (
                  <EntityTasksList
                    entity={{
                      uid: narrative.uid,
                      name: narrative.title,
                      type: EntityTypes.narrative,
                    }}
                    setTasksCount={setTasksCount}
                    history={props.history}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
}
