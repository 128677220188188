import { isAbsoluteUrl, joinPath } from '../helpers/path-helpers';

export const AppConfig = {
    isDebug: process.env.REACT_APP_DEBUG ?? true,

    apiProtocol: process.env.REACT_APP_API_PROTOCOL ?? 'http',
    apiHost: process.env.REACT_APP_API_HOST ?? 'affectifi.local',
    apiPort: process.env.REACT_APP_API_PORT ?? 8080,
    apiPath: process.env.REACT_APP_API_PATH ?? '/graphql',

    protocol: process.env.REACT_APP_PROTOCOL ?? 'http',
    host: process.env.REACT_APP_HOST ?? 'admin.affectifi.local',
    port: process.env.REACT_APP_PORT ?? 8080,
    path: process.env.REACT_APP_PATH ?? '/',

    clientProtocol: process.env.REACT_APP_CLIENT_PROTOCOL ?? 'http',
    clientHost: process.env.REACT_APP_CLIENT_HOST ?? 'affectifi.local',
    clientPort: process.env.REACT_APP_CLIENT_PORT ?? 8080,
    clientPath: process.env.REACT_APP_CLIENT_PATH ?? '/',

    filesServer: process.env.REACT_APP_FILES_SERVER ?? 'https://s3.amazonaws.com/resource.dev.affectifi.com/',
    directUploadToS3: process.env.REACT_APP_DIRECT_UPLOAD_TO_S3 ?? true,

    testUserEmail: 'test@affectifi123.tt',
    testUserPsw: '1',

    tokenName: 'accessToken',

    seriesMaxSeasonsCount: 20,
    seriesMaxNumberPerSeason: 40,

    achievementFilePath: 'static/files/achievements',
    actionTendencyFilePath: 'static/files/action_tendency',
    regulationStrategyFilePath: 'static/files/regulation_strategy',
    exerciseFilePath: 'static/files/exercises',
    characterFilePath: 'static/files/characters',
    learningChunkFilePath: 'static/files/learning_chunks',
    narrativeFilePath: 'static/files/narratives',
    emotionCategoryFilePath: 'static/files/emotion_categories',
    episodeFilePath: 'static/files/episodes',
    sceneFilePath: 'static/files/scenes',
    unitFilePath: 'static/files/units',
    emotionEpisodeFilePath: 'static/files/emotion_episodes'
};

export const LEARNING_CHUNK_FILE_URL_PLACEHOLDER = '%LEARNING_CHUNK_FILE_URL%';


export function getAbsoluteUrl(...path: string[]) {
    const appUrl = AppConfig.protocol + '://' + AppConfig.host + (AppConfig.port ? `:${AppConfig.port}` : '') + AppConfig.path;
    return joinPath(appUrl, ...path);
}

export function getAbsoluteClientUrl(...path: string[]) {
    const appUrl = AppConfig.clientProtocol + '://' + AppConfig.clientHost + (AppConfig.clientPort ? `:${AppConfig.clientPort}` : '') + AppConfig.clientPath;
    return joinPath(appUrl, ...path);
}


export function getAchievementFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file;
    return joinPath(AppConfig.filesServer, AppConfig.achievementFilePath, uid, file);
}

export function getRegulationStrategyFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file;
    return joinPath(AppConfig.filesServer, AppConfig.regulationStrategyFilePath, uid, file);
}

export function getActionTendencyFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file;
    return joinPath(AppConfig.filesServer, AppConfig.actionTendencyFilePath, uid, file);
}

export function getCharacterFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file;
    return joinPath(AppConfig.filesServer, AppConfig.characterFilePath, uid, file);
}

export function getExerciseFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file;
    return joinPath(AppConfig.filesServer, AppConfig.exerciseFilePath, uid, file);
}

export function getLearningChunkFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file as string;
    return joinPath(AppConfig.filesServer, AppConfig.learningChunkFilePath, uid, file);
}

export function getEmotionEpisodeFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file as string;
    return joinPath(AppConfig.filesServer, AppConfig.emotionEpisodeFilePath, uid, file);    
}

export function getNarrativeFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file;
    return joinPath(AppConfig.filesServer, AppConfig.narrativeFilePath, uid, file);
}

export function getEmotionCategoryFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file;
    return joinPath(AppConfig.filesServer, AppConfig.emotionCategoryFilePath, uid, file);
}

export function getEpisodeFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file;
    return joinPath(AppConfig.filesServer, AppConfig.episodeFilePath, uid, file);
}


export function getSceneFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file;
    return joinPath(AppConfig.filesServer, AppConfig.sceneFilePath, uid, file);
}

export function getUnitFilePath(uid: string, file?: string) {
    if(isAbsoluteUrl(file)) return file;
    return joinPath(AppConfig.filesServer, AppConfig.unitFilePath, uid, file);
}
