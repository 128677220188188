import { faArrowLeft, faTrashAlt, faUnlock, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { userPlanDelete } from '../../../api/actions/user-plan/user-plan-delete';
import { getUserPlan } from '../../../api/actions/user-plan/user-plan-get';
import { getAllAchievements } from '../../../api/actions/achievement/achievement-get-all';
import { userPlanSave } from '../../../api/actions/user-plan/user-plan-save';
import { userPlanEnable } from '../../../api/actions/user-plan/user-plan-enable';
import { userPlanDisable } from '../../../api/actions/user-plan/user-plan-disable';
import { Block } from '../../../components/shared/block/block';
import { AddBreadcrumbsItem } from '../../../components/shared/breadcrumbs/breadcrumbs';
import { PlanCertificationList } from './plan-certification-list';
import { Button } from '../../../components/shared/button/button';
import { LinkButton } from '../../../components/shared/link-button/link-button';
import { Loader } from '../../../components/shared/loader/loader';
import { DeleteQuestionPopup } from '../../../components/shared/popup/delete-question-popup';
import { basePerformError } from '../../../helpers/error-helpers';
import { getParentPath, joinPath } from '../../../helpers/path-helpers';
import { IUserPlan } from '../../../models/user-plan';
import { IAchievement } from '../../../models/achievement';
import { IDictionary } from '../../../types/dictionary';
import { SelectField } from '../../../components/shared/select/select';
import { UserAccessTypes } from '../../../models/enums/user-access-types';
import { PlanTypes } from '../../../models/enums/plan-types';
import { SectorTypes } from '../../../models/enums/sector-types';
import { CheckBoxWrapper } from '../../../components/shared/checkbox/checkbox-wrapper';
import styles from './plan-editor.module.scss';


enum FormFields {
    name = 'name',
    type = 'type',
    accessType = 'accessType',
    oneOff = 'oneOff',
    finalUnitIndex = 'finalUnitIndex',
    description = 'description',
    priceId = 'priceId',
    free = 'free',
    default = 'default',
    sector = 'sector',
    maxLicenseCount = 'maxLicenseCount',
    adminPermissions = 'adminPermissions',
    learnersAllowed = 'learnersAllowed',
    maxGroupsPerEducator = 'maxGroupsPerEducator'
}

interface IForm {
    [FormFields.name]: string,
    [FormFields.type]: string,
    [FormFields.accessType]: string,
    [FormFields.oneOff]?: boolean,
    [FormFields.finalUnitIndex]?: number | null,
    [FormFields.description]?: string,
    [FormFields.priceId]?: string,
    [FormFields.free]?: boolean,
    [FormFields.default]?: boolean,
    [FormFields.sector]?: string,
    [FormFields.adminPermissions]?: boolean,
    [FormFields.maxLicenseCount]?: number | null,
    [FormFields.learnersAllowed]?: boolean,
    [FormFields.maxGroupsPerEducator]?: number | null,
}

export function PlanEditor(props: RouteComponentProps<{uid: string}>) {

    const [loading, setLoading] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [plan, setPlan] = useState<IUserPlan>();
    const [certifications, setCertifications] = useState<IAchievement[]>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            if(uid || props.match.params.uid !== '_') {
                setPlan(await getUserPlan(uid || props.match.params.uid));
                setCertifications(await getAllAchievements('certification'));
            }
            else {
                setPlan({} as IUserPlan);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.match.params.uid]);

    useEffect(() => {
        loadData().catch(() => {/** */});
    }, []);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting }: { setSubmitting: (status: boolean) => void, setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newPlan: Partial<IUserPlan> = {
                uid: plan?.uid,
                name: values.name,
                type: values.type,
                accessType: values.accessType,
                finalUnitIndex: values.finalUnitIndex || 0,
                description: values.description,
                priceId: values.priceId,
                free: values.free,
                oneOff: values.oneOff,
                default: values.default,
                sector: values.sector,
                maxLicenseCount: values.maxLicenseCount || undefined,
                adminPermissions: values.adminPermissions,
                learnersAllowed: values.learnersAllowed === true,
                maxGroupsPerEducator: values.maxGroupsPerEducator || undefined,
            };

            const uid = await userPlanSave(newPlan);
            if(!plan?.uid) {
                props.history.push(joinPath(props.match.url.replace(/\/_\/?$/, ''), uid));
            }
            await loadData(uid);
            toast.success('Item has been successfully saved');
        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            name: Yup.string().label('Name').trim().required().min(2).max(75),
            type: Yup.string().label('Access Type').trim().required(),
            accessType: Yup.string().label('Access Type').trim().required(),
            finalUnitIndex: Yup.number().label('Final Unit Index').integer().nullable(),
            description: Yup.string().label('Description').trim().min(3).max(500),
            priceId: Yup.string().label('Price Id').trim().min(2).max(50),
            maxLicenseCount: Yup.number().label('Included Licenses').integer().nullable(),
            maxGroupsPerEducator: Yup.number().label('Groups per Educator').integer().nullable()
        });
    };

    const enableUserPlan = React.useCallback(async () => {
        if(!plan?.uid) return;
        try {
            await userPlanEnable(plan?.uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [loadData, props.history, plan?.uid]);


    const disableUserPlan = React.useCallback(async () => {
        if(!plan?.uid) return;
        try {
            await userPlanDisable(plan?.uid);
            await loadData();
            toast.success('Item has been successfully saved');
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [loadData, props.history, plan?.uid]);

    const deletePlan = async () => {
        if(!plan?.uid) return;
        await userPlanDelete(plan?.uid);
        setDeleteDialogVisible(false);
    };

    const renderForm = ({ errors, touched, values }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>

                    <div className="form-buttons">

                        { !plan?.enabled && (<>
                          <LinkButton onClick={enableUserPlan}>
                              <FontAwesomeIcon icon={faUnlock} /> Enable
                            </LinkButton><br />
                        </>)}
                        { plan?.enabled && (<>
                          <LinkButton onClick={disableUserPlan} className="orange">
                              <FontAwesomeIcon icon={faLock} /> Disable
                            </LinkButton><br />
                        </>)}

                        <div></div>
                        { plan?.uid && (
                            <LinkButton onClick={() => setDeleteDialogVisible(true)} className="red"><FontAwesomeIcon icon={faTrashAlt} /> Delete</LinkButton>
                        )}
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Name
                            </div>
                            <Field type="text" name={FormFields.name} />
                        </label>
                        <div className="errors">{touched.name && errors.name}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.type}
                                emptyTitle=""
                                data={[
                                    { uid: PlanTypes.individual, name: 'Individual'},
                                    { uid: PlanTypes.institutional, name: 'Institutional'},
                                ]}
                            />
                        </label>
                        <div className="errors">{touched.type && errors.type}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Access Type
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.accessType}
                                emptyTitle=""
                                data={[
                                    { uid: UserAccessTypes.full, name: 'Full'},
                                    { uid: UserAccessTypes.partial, name: 'Partial'},
                                    { uid: UserAccessTypes.none, name: 'None'},
                                ]}
                            />
                        </label>
                        <div className="errors">{touched.accessType && errors.accessType}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Final Unit Index
                            </div>
                            <Field type="number" name={FormFields.finalUnitIndex} />
                        </label>
                        <div className="errors">{touched.finalUnitIndex && errors.finalUnitIndex}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Default">
                                <Field type="checkbox" name={FormFields.default} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.default && errors.default}</div>
                    </div>
                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="One Off">
                                <Field type="checkbox" name={FormFields.oneOff} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.oneOff && errors.oneOff}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Free">
                                <Field type="checkbox" name={FormFields.free} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.free && errors.free}</div>
                    </div>
                    {!values.free && (<div className="form-item">
                        <label>
                            <div className="form-label">
                                Price Id
                            </div>
                            <Field type="text" name={FormFields.priceId} />
                        </label>
                        <div className="errors">{touched.priceId && errors.priceId}</div>
                    </div>)}

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Learners Allowed (only educators if false)">
                                <Field type="checkbox" name={FormFields.learnersAllowed} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.learnersAllowed && errors.learnersAllowed}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Sector
                            </div>
                            <Field
                                component={SelectField}
                                name={FormFields.sector}
                                emptyTitle=""
                                data={[
                                    { uid: SectorTypes.education_nonprofit, name: 'Education / Nonprofit'},
                                    { uid: SectorTypes.mental_health, name: 'Mental Health & Wellness'},
                                    { uid: SectorTypes.corporate, name: 'Corporate'},
                                ]}
                            />
                        </label>
                        <div className="errors">{touched.sector && errors.sector}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                &nbsp;
                            </div>
                            <CheckBoxWrapper label="Admin Permissions">
                                <Field type="checkbox" name={FormFields.adminPermissions} />
                            </CheckBoxWrapper>
                        </label>
                        <div className="errors">{touched.adminPermissions && errors.adminPermissions}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                License Count
                            </div>
                            <Field type="number" name={FormFields.maxLicenseCount} />
                        </label>
                        <div className="errors">{touched.maxLicenseCount && errors.maxLicenseCount}</div>
                    </div>

                    <div className="form-item">
                        <label>
                            <div className="form-label">
                                Max Groups per Educator (0 for no limit)
                            </div>
                            <Field type="number" name={FormFields.maxGroupsPerEducator} />
                        </label>
                        <div className="errors">{touched.maxGroupsPerEducator && errors.maxGroupsPerEducator}</div>
                    </div>

                    <div className="form-buttons">
                        <Button className="gray" to={getParentPath(props.match.url, 1)}>
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit"><span>Save</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !plan ? null : (<>
        <Block className={styles.editor}>

            <AddBreadcrumbsItem
                title="Plans"
                url={getParentPath(props.match.url, 1)}
            />
            <AddBreadcrumbsItem
                key={plan.uid}
                title={plan.uid ? `Edit: ${plan.name}` : 'Add Plan'}
                url={props.match.url}
            />
            <Formik<IForm>
                initialValues={{
                    name: plan?.name || '',
                    type: plan?.type || '',
                    accessType: plan?.accessType || '',
                    finalUnitIndex: plan?.finalUnitIndex || null,
                    description: plan?.description || '',
                    priceId: plan?.priceId || '',
                    free: plan?.free || false,
                    oneOff: plan?.oneOff || false,
                    default: plan?.default || false,
                    adminPermissions: plan?.adminPermissions || false,
                    sector: plan?.sector || undefined,
                    maxLicenseCount: plan?.maxLicenseCount || null,
                    learnersAllowed: plan?.learnersAllowed || false,
                    maxGroupsPerEducator: plan?.maxGroupsPerEducator || null,
                }}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmit}
            >
                {renderForm}

            </Formik>

        </Block>

            {plan.uid && (<>
                <PlanCertificationList 
                    plan={plan} 
                    certifications={certifications} 
                    reloadData={loadData}/>
                </>)
            }


        {deleteDialogVisible && (
            <DeleteQuestionPopup
                title="Plan Deletion"
                question={<>Are you sure want to delete this plan?</>}
                onClose={() => setDeleteDialogVisible(false)}
                onDelete={deletePlan}
                onFinish={() => { props.history.push(getParentPath(props.match.url, 1)) }}
            />
        )}
    </>);
}
