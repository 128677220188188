import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IRegulationStrategy } from '../../../models/regulation-strategy';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetRegulationStrategy($uid: String!){
        regulation_strategy(uid: $uid) {
            uid,
            description,
            attempted,
            success,
            intendedOutcome: intended_outcome,
            createdAt: created_at,
            updatedAt: updated_at,

            createdBy: created_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },

            updatedBy: updated_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },


            doneBy: done_by {
                uid,
            },

            regulationCategory: regulation_category {
                uid,
            },

            goal: goal {
                uid,
            },

        }
    }
`;

export async function getRegulationStrategy(uid: string): Promise<IRegulationStrategy> {
    const result: FetchResult<{ regulation_strategy: IRegulationStrategy }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.regulation_strategy, ['__typename']);
}
