import { FetchResult, gql } from 'apollo-boost';
import { IUserPlan } from '../../../models/user-plan';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query {
        user_plans {
            uid,
            name,
            accessType: access_type,
            type,
            free,
            finalUnitIndex: final_unit_index,
            description,
            priceId: price_id,
            enabled
        }
    }
`;

export async function getAllUserPlans(): Promise<IUserPlan[]> {
    const result: FetchResult<{ user_plans: IUserPlan[] }> = await graphqlClient.query({
        query: QUERY,
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_plans;
}
