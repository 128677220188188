import { FetchResult, gql } from 'apollo-boost';
import { removeFields } from '../../../helpers/common-helpers';
import { IGoal } from '../../../models/goal';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const QUERY = gql`
    query GetGoal($uid: String!){
        goal(uid: $uid) {
            uid,
            description,
            congruent,
            process,
            createdAt: created_at,
            updatedAt: updated_at,

            createdBy: created_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },

            updatedBy: updated_by {
                uid,
                firstName: first_name,
                lastName: last_name,
            },

            appraisal {
                uid,
            },

            owner {
                uid,
            },

            nonOwner: non_owner {
                uid,
            },
        }
    }
`;

export async function getGoal(uid: string): Promise<IGoal> {
    const result: FetchResult<{ goal: IGoal }> = await graphqlClient.query({
        query: QUERY,
        variables: { uid },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return removeFields(result.data.goal, ['__typename']);
}
