/* eslint-disable sonarjs/no-extra-arguments */
import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components/shared/button/button';
import { UploaderField } from '../../../components/shared/uploader/uploader';
import { UploaderImagePreview } from '../../../components/shared/uploader/uploader-image-preview';
import { getRegulationStrategyFilePath } from '../../../config/app-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import styles from '../emotion-categories/emotion-categories-list.module.scss';
import { IRegulationStrategyDefaultForm, IRegulationStrategy } from '../../../models/regulation-strategy';
import { regulationStrategySave } from '../../../api/actions/regulation-strategy/regulation-strategy-save';
import { deleteRegulationStrategyImage } from '../../../api/actions/regulation-strategy/regulation-strategy-delete-image';
import { toast } from 'react-toastify';

interface LocationState {
  strategy?: {
    uid: string;
    description: string;
    imgFileName: string;
  };
}

export const RegulationStrategyEdit = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const routeState = location.state as {
    strategy: IRegulationStrategyDefaultForm | undefined;
  };

  const strategy = location.state?.strategy;
  const filePath = strategy
    ? getRegulationStrategyFilePath(strategy.uid, strategy.imgFileName)
    : getRegulationStrategyFilePath('');
  const [imagePresent, setImagePresent] = useState(!!strategy?.imgFileName);

  const saveRegulationStrategy = async (values: IRegulationStrategyDefaultForm) => {
    console.log('Submitting', values);

    const newRegulationStrategy: Partial<IRegulationStrategy> = {
                uid: strategy?.uid,
                description: values.description,
            };

    try {
      const result = await regulationStrategySave(
        newRegulationStrategy,
        //@ts-ignore
        values.image ? values.image.file : undefined
      );
      if (result) {
        toast.success('Saved regulation strategy');
        history.push('/libraries/regulation-defaults');
      } else {
        toast.error('Error saving regulation strategy');
      }
    } catch (error) {
      console.error('Error saving regulation strategy:', error);
    }
  };

  const handleDeleteImage = async (uid: string) => {
    const { success } = await deleteRegulationStrategyImage(uid);
    if (success) {
      toast.success('Image has been deleted.');
      setImagePresent(false);
    }
  };

  const initialFormState: IRegulationStrategyDefaultForm = routeState?.strategy || {
    uid: '',
    description: '',
  };

  return (
    <div className={styles.formContainer}>
      <Formik
        initialValues={initialFormState}
        onSubmit={async (values, { setSubmitting }) => {
          await saveRegulationStrategy(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <h3>Regulation Strategy</h3>
            {strategy && strategy.uid && (
              <>
                <div className="form-item">
                  <span>UID: </span>
                  {strategy.uid}
                </div>
              </>
            )}
            {!strategy && (
              <div className="form-item">
                <label>
                  <div className="form-label">UID</div>
                  <Field name="uid" type="text" placeholder="UID" />
                </label>
              </div>
            )}

            <div className="form-item">
              <label>
                <div className="form-label">Description</div>
                <Field name="description" type="text" placeholder="description" />
              </label>
            </div>

            {!imagePresent && (
              <div className="form-item">
                <label>Image</label>
                <Field
                  name="image"
                  acceptFileTypes=".png,.jpg,.jpeg,.gif"
                  component={UploaderField}
                  path={filePath}
                  previewComponent={UploaderImagePreview}
                />
              </div>
            )}

            {imagePresent && strategy && strategy.imgFileName && (
              <div className="form-item">
                <label htmlFor="current image"></label>
                <div onClick={() => handleDeleteImage(strategy.uid)}>
                  <span className={styles.deleteIcon}>
                    Delete current image{' '}
                    <FontAwesomeIcon
                      className={styles.deleteIcon}
                      icon={faTrashAlt}
                    />
                  </span>
                </div>
                <img
                  className={styles.emotionImage}
                  src={filePath}
                  alt={strategy.imgFileName}
                />
                <div>{strategy.imgFileName}</div>
              </div>
            )}

            <div className="form-item">
              <Button type="submit" disabled={isSubmitting}>
                {strategy && strategy.uid ? 'Update' : 'Create'}
              </Button>
              <Button
                onClick={() => history.push('/libraries/regulation-defaults')}
              >
                Back
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
