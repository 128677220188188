import { FetchResult, gql } from 'apollo-boost';
import { IUserPlan } from '../../../models/user-plan';
import { MultiError } from '../../../types/multi-error';
import { graphqlClient } from '../../graphql-client';

const MUTATION = gql`
    mutation UserPlanSave($userPlan: UserPlanSaveInput!){
        user_plan_save (input_data: $userPlan) {
            uid
        }
    }
`;

export async function userPlanSave(userPlan: Partial<IUserPlan>): Promise<string> {
    const result: FetchResult<{ user_plan_save: { uid: string }}> = await graphqlClient.mutate({
        mutation: MUTATION,
        variables: { userPlan: {
            uid: userPlan.uid,
            name: userPlan.name,
            access_type: userPlan.accessType,
            final_unit_index: userPlan.finalUnitIndex,
            description: userPlan.description,
            one_off: userPlan.oneOff,
            price_id: userPlan.priceId,
            type: userPlan.type,
            free: userPlan.free,
            default: userPlan.default,
            admin_permissions: userPlan.adminPermissions,
            sector: userPlan.sector,
            max_license_count: userPlan.maxLicenseCount,
            learners_allowed: userPlan.learnersAllowed,
            max_groups_per_educator: userPlan.maxGroupsPerEducator
        } },
        errorPolicy: 'all',
    });

    if(!result.data || result.errors && result.errors.length) {
        throw new MultiError(result.errors || ['Unknown error']);
    }

    return result.data.user_plan_save?.uid;
}
