import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getAppraisalsByParticipant } from '../../../../../../api/actions/appraisal/appraisals-get-by-participant';
import { getGoal } from '../../../../../../api/actions/goal/goal-get';
import { goalSave } from '../../../../../../api/actions/goal/goal-save';
import { Button } from '../../../../../../components/shared/button/button';
import { Loader } from '../../../../../../components/shared/loader/loader';
import { basePerformError } from '../../../../../../helpers/error-helpers';
import { cutStr } from '../../../../../../helpers/string-helpers';
import { IAppraisal } from '../../../../../../models/appraisal';
import { IGoal, GoalProcessTypes } from '../../../../../../models/goal';
import { IParticipant } from '../../../../../../models/participant';
import { IDictionary } from '../../../../../../types/dictionary';
import styles from './goal-editor.module.scss';
import { SelectField } from '../../../../../../components/shared/select/select';
import moment from 'moment';

interface IProps extends RouteComponentProps {
    uid?: string,
    participantUid: string,
    has: boolean,
    goal_process: GoalProcessTypes,
    onClose: () => void,
    reload(): void,
}

enum FormFields {
    description = 'description',
    appraisal = 'appraisal',
    congruent = 'congruent',
    process = 'process'
}

interface IForm {
    [FormFields.description]?: string,
    [FormFields.appraisal]?: string,
    [FormFields.congruent]?: number,
    [FormFields.process]?: GoalProcessTypes,
}

function GoalEditor(props: IProps) {

    const [loading, setLoading] = useState(false);
    const [goal, setGoal] = useState<IGoal>();
    const [appraisals, setAppraisals] = useState<IAppraisal[]>();

    const loadData = useCallback(async (uid?: string) => {
        try {
            if(props.has && props.goal_process === GoalProcessTypes.appraisal) {
                setAppraisals(await getAppraisalsByParticipant(props.participantUid));
            }
            if(uid || props.uid) {
                setGoal(await getGoal(uid || props.uid || ''));
            }
            else {
                setGoal({} as IGoal);
            }
        }
        catch(err) {
            basePerformError(err, props.history);
        }
    }, [props.history, props.has, props.goal_process, props.participantUid, props.uid]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleSubmit = async (
        values: IForm,
        { setSubmitting, setErrors }: { setSubmitting: (status: boolean) => void; setErrors: (errors: IDictionary<string>) => void },
    ) => {
        setLoading(true);
        try {
            const newGoal: Partial<IGoal> = {
                uid: goal?.uid,
                description: values.description,
            };
            if(props.has && props.goal_process === GoalProcessTypes.appraisal) {
                newGoal.appraisal = { uid: values.appraisal } as IAppraisal;
                newGoal.congruent = values.congruent === undefined || values.congruent === null ? undefined : (values.congruent * 1 === 1  ? true : false)
            }
            newGoal.process = values.process === undefined || values.process === null ? undefined : values.process
            if(!newGoal.uid) {
                if(props.has) {
                    newGoal.owner = { uid: props.participantUid } as IParticipant;
                }
                else {
                    newGoal.nonOwner = { uid: props.participantUid } as IParticipant;
                }
            }
            const uid = await goalSave(newGoal);
            await loadData(uid);
            props.reload();
            toast.success('Item has been successfully saved');

        }
        catch (err) {
            basePerformError(err, props.history);
        }
        setSubmitting(false);
        setLoading(false);
    };

    const getValidationSchema = () => {
        return Yup.object<IForm>({
            description: Yup.string().trim().required().min(3).max(255),
            process: Yup.mixed<GoalProcessTypes>().required(),
            ...(props.has && props.goal_process === GoalProcessTypes.appraisal ? {
                appraisal: Yup.string().trim().when('process', {
                    is: GoalProcessTypes.appraisal,
                    then: Yup.string().trim().required(),
                }),
                congruent: Yup.number().required(),
            } : {}),
        });
    };

    const renderForm = ({ errors, touched, isValid }: FormikProps<IForm>): React.ReactElement => {
        return (
            <div className={styles.formContainer}>
                <Form noValidate>
                    <div className="form-item">
                        <label>
                            <div className="form-label required">
                                Description
                            </div>
                            <Field component="textarea" name={FormFields.description} />
                        </label>
                        <div className="errors">{touched.description && errors.description}</div>
                    </div>
                    { props.has && props.goal_process===GoalProcessTypes.appraisal && (<>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Appraisal
                                </div>
                                <Field
                                    component={SelectField}
                                    name={FormFields.appraisal}
                                    emptyTitle=""
                                    data={ appraisals?.sort((a1, a2) =>
                                            a1.description > a2.description ? 1 : (a1.description < a2.description ? -1 : 0),
                                        )
                                        .map((a: IAppraisal) => ({ uid: a.uid, name: cutStr(a.description, 40) }))
                                    }
                                />
                            </label>
                            <div className="errors">{touched.appraisal && errors.appraisal}</div>
                        </div>
                        <div className="form-item">
                            <label>
                                <div className="form-label required">
                                    Congruent
                                </div>
                                <Field
                                    component={SelectField}
                                    name={FormFields.congruent}
                                    emptyTitle=""
                                    data={[
                                        { uid: 1, name: 'Yes' },
                                        { uid: 0, name: 'No' },
                                    ]}
                                />
                            </label>
                            <div className="errors">{touched.congruent && errors.congruent}</div>
                        </div>
                    </>)}
                    { goal?.createdBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Created
                                </div>
                                <div>
                                    <strong>
                                        {goal.createdBy?.firstName}
                                        &nbsp;
                                        {goal.createdBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(goal.createdAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}
                    { goal?.updatedBy && (
                        <div className="form-item">
                            <label>
                                <div className="form-label">
                                    Updated
                                </div>
                                <div>
                                    <strong>
                                        {goal.updatedBy?.firstName}
                                        &nbsp;
                                        {goal.updatedBy?.lastName}
                                    </strong>
                                    &nbsp; at &nbsp;
                                    {moment(goal.updatedAt).format('ddd, MM/DD/YYYY h:mm a')}
                                </div>
                            </label>
                        </div>
                    )}

                    <div className="form-buttons">
                        <Button onClick={props.onClose} className="gray small">
                            <FontAwesomeIcon icon={faArrowLeft} /> <span>Back</span>
                        </Button>
                        { loading
                            ? (<Loader />)
                            : (<Button type="submit" className="small"><span>{goal?.uid ? 'Save' : 'Create'}</span></Button>)
                        }
                    </div>
                </Form>
            </div>
        );
    };

    return !goal ? null : (
        <Formik
            initialValues={{
                description: (goal && goal.description) || '',
                appraisal: (goal && goal.appraisal && goal.appraisal.uid) || '',
                congruent: goal && (goal.congruent === undefined ? undefined : (goal.congruent ? 1 : 0)),
                process: goal && (!goal.process || goal.process === undefined ? props.goal_process : goal.process),
            }}
            validationSchema={getValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
}

const GoalEditorWithRouter = withRouter(GoalEditor);
export { GoalEditorWithRouter as GoalEditor };
